import React from 'react';
import {useLocation} from 'react-router-dom';
import styles from './NotFoundPage.module.css';


export default function NotFoundPage() {
  let location = useLocation();

  return (
    <div className={styles.container}>
      <div className={styles.img}>
        <img src="https://media.giphy.com/media/5nmobhwPiNsKELWk69/giphy.gif" alt="404 - what to do?!" title="404 - what to do?!" />
      </div>
      <header className={styles.header}>
        <h1>This is just a regular 404 page. Not more or less.</h1>
      </header>
      <p className={styles.text}>
        Page: <code className={styles.code}>{location.pathname}</code> not found!
      </p>
    </div>
  )
};
