import React from 'react';
import ReactDOM from 'react-dom';
import DefineRoute from './pages/DefineRoute';
import styles from './index.css';

ReactDOM.render(
  <div className={styles.container}>
    <DefineRoute />
  </div>,
  document.getElementById('root')
);
