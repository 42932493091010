import React from 'react';
import styles from './DefaultPage.module.css';

function DefaultPage() {
  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        <img src="https://media.giphy.com/media/26ufplp8yheSKUE00/giphy.gif" className={styles.AppLogo} alt="logo" />
        <p>
          Sir, did you just find a pretty awesome portfolio? I sure think so!
        </p>
        <p>
          More to come, later..
        </p>
      </header>
    </div>
  );
}

export default DefaultPage;
