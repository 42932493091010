import React from 'react';
import { 
  BrowserRouter as Router,
  Switch,
  Route} from 'react-router-dom';
import DefaultPage from '../DefaultPage';
import NotFoundPage from '../NotFoundPage';

export default function DefineRoute() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={DefaultPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}
